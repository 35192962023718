@import "assets/stylesheets/colors";

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}
@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.4;
    }
    100% {
        opacity: 1;
    }
}

.placeholder-loading {
    width: 100%;
    height: 12px;
    -webkit-animation: flickerAnimation 1.3s infinite;
    -moz-animation: flickerAnimation 1.3s infinite;
    -o-animation: flickerAnimation 1.3s infinite;
    animation: flickerAnimation 1.3s infinite;
}
