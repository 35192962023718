@import "assets/stylesheets/colors";

.success-modal-container {
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;

  box-shadow: 0 0 1rem 0 rgba($color: $primary-bg, $alpha: 0.2);;
  background-color: rgba($color: $primary-bg, $alpha: 0.95);
  backdrop-filter: blur(5px);

  .success-checked-icon {
    align-self: center;
    margin: auto;
    margin-top: 40px;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}
