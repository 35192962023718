@import "assets/stylesheets/colors";
@import 'assets/stylesheets/typography';

.input-text-border-container {
  width: 100%;
  line-height: clamp(22px, 2.5vw, 40px);
  .input-underline {
    display: none;
  }
  .input-text {
    display: flex;
  }
  .input-core{
    margin-top: 0px !important;
    border-radius: 5px;
    border: 1px white solid !important;
    padding-left: 12px;
    height: clamp(22px, 2.5vw, 40px);
    @extend .input-text-scale;
    color: $primary-white !important;
    &::placeholder {
      @extend .input-text-scale;
      color: $primary-white !important;
    }
    &:disabled {
      opacity: .7 !important;
    }
  }
}
