@import "assets/stylesheets/colors";

.page-container {
  .content-wrapper {
    height: fit-content;
    .search-wrapper {
      .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .user-wrapper {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transition:all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        padding: 7px;
        border-radius: 10px;
        background-color: $primary-bg;
      }
    }
    .active { 
      outline: none;
        transition:all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        padding: 7px;
        border-radius: 10px;
        background-color: $primary-bg;
    }
    .detail-wrapper {
      .topic-content {
        padding: 20px 0 20px 0 ;
        border-radius: 10px;
        background-color: $primary-bg;
      }
      .detail-content {
        .icon-detail {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          background-color: $primary-text;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          margin-top: 8px;
        }
      }
    }
  }
  .responsive-font {
    font-size: 1rem
  }
  .display-date-text {
    font-family: "Roboto-Medium";
    font-size: 18px;
    line-height: 18px;
    color: $primary-red;
  }
}
