@import "../../../node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/bootstrap/scss/bootstrap-reboot.scss";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/mixins/table-row";
@import "../../../node_modules/bootstrap/scss/mixins/grid";
@import "../../../node_modules/bootstrap/scss/mixins/grid-framework";
// @import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";


@import "./fonts.scss";
@import "./colors.scss";
@import "./spacing.scss";
@import "./typography.scss";
@import "./scrollbars.scss";

body {
  @extend .custom-scrollbar;
  background-color: $primary-bg;
  font-family: "Roboto-Medium";
  height: 100vh;
  width: 100%;
  margin: 0;
}
