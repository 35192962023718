@import 'assets/stylesheets/colors';
.transaction-filter-form-container {
  .custom-scrollbar::-webkit-scrollbar-track {
    background: $secondary-bg;
  }
  display: flex;
  flex-direction: column;
  gap: clamp(3px, calc(var(--vh, 1vh) * 6 - 16px), 12px);
  & > div {
    line-height: clamp(22px, 2.5vw, 40px) !important;
  }
  .form-flex-gap {
    display: flex;
    gap: clamp(3px, calc(var(--vh, 1vh) * 6 - 16px), 8px);
  }
}