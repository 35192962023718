@import "assets/stylesheets/fonts";

.text-running-container {
  position: fixed;
  top: 0px;
  z-index: 3;
  width: 100%;

  .text-running {
    height: 26px;
    overflow: hidden;
    position: relative;
    background-color: #2b2e32;

    &.light-mode {
      background-color: #bb130a;
    }

    &.dark-mode {
      background-color: #1171ec;
    }

    &.darker-mode {
      background-color: #2b2e32;
    }
  }

  .text-running p {
    position: absolute;
    width: 800px;
    height: 100%;
    margin: 0;
    font-family: "Roboto-Regular";
    font-size: 14px;
    line-height: 26px;
    color: white;
    text-align: center;
    /* Starting position */
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    /* Apply animation to this element */
    -moz-animation: scroll-left 20000ms linear infinite;
    -webkit-animation: scroll-left 20000ms linear infinite;
    animation: scroll-left 20000ms linear infinite;
  }

  @-moz-keyframes scroll-left {
    0% {
      -moz-transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-100%);
    }
  }
  @-webkit-keyframes scroll-left {
    0% {
      -webkit-transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
    }
  }
  @keyframes scroll-left {
    0% {
      -moz-transform: translateX(100%); /* Browser bug fix */
      -webkit-transform: translateX(100%); /* Browser bug fix */
      transform: translateX(100%);
    }
    100% {
      -moz-transform: translateX(-100%); /* Browser bug fix */
      -webkit-transform: translateX(-100%); /* Browser bug fix */
      transform: translateX(-100%);
    }
  }
}
