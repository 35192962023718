@import "assets/stylesheets/colors";
.button-link-text,
.button-link-text-bold {
  &:hover {
    // text-decoration: underline;
  }

  &.disabled {
    color: $secondary-text;
    cursor: pointer;
    &:hover {
      cursor: pointer;
      color: $primary-text !important;
      text-decoration: unset;
    }
  }
  &.underline {
    text-decoration: underline;
    &:hover {
      text-decoration: unset;
    }
  }  
}

