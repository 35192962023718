@import "./colors.scss";

// TODO: Customize Scrollbar for Infinity Scrollbar
.infinite-scroll-component::-webkit-scrollbar {
    width: 7px;               /* width of the entire scrollbar */
  }
  .infinite-scroll-component::-webkit-scrollbar-track {
    background: $secondary-bg;        /* color of the tracking area */
  }
  .infinite-scroll-component::-webkit-scrollbar-thumb {
    background-color: darken($secondary-text, 15);    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    border: 3px solid darken($secondary-text, 15);  /* creates padding around scroll thumb */
  }
  
  .infinite-scroll-component {
    scrollbar-width: thin;          /* "auto" or "thin"  */
    scrollbar-color: $secondary-bg darken($secondary-text, 15);   /* scroll thumb & track */
  }

.custom-scrollbar::-webkit-scrollbar {
  width: 7px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: $primary-bg;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: darken($secondary-text, 15);
  border-radius: 20px;
  border: 3px solid darken($secondary-text, 15);
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $secondary-bg darken($secondary-text, 15);
}
