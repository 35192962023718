@import 'assets/stylesheets/colors';
@import 'assets/stylesheets/scrollbars';
  html {
    height: calc(var(--vh, 1vh) * 100);
    body {
      width: 100%;
    }
  }
  .root {
    height: 100%;
  }
  .text-running-container {
    display: none;
    @media (min-height: 720px) {
      display: block;
    }
  }
  .navbar-container {
    margin-top: -26px;
    @media (min-height: 720px) {
      margin-top: 0;
    }
  }
.transaction-management{
  .page-container {
    position: fixed;
    // overflow-y: auto;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    padding-top: 40px !important;
    @media (min-height: 720px) {
      padding-top: 66px !important;
    }
    .page-header{
      padding: 0 clamp(12px, calc(5vw - 36px), 26px);
      margin: clamp(4px, calc(var(--vh, 1vh) * 5.5 - 14px), 16px) 0;
    }
    .content-wrapper {
      gap: 12px;
      padding: clamp(12px, calc(5vw - 36px), 26px);
      padding-top: 12px;
      .input-wrapper-transaction {
        flex: 0 0 25%;
        min-width: 140px;
        position: relative;
        .user-stat {
          display: flex;
          margin-top: clamp(8px, calc(10vh - 36px), 16px);
          justify-content: space-between;
          flex-direction: column;
          height: 40%;
          max-height: 70px;
        }
        
      }
      .search-wrapper {
        gap: clamp(8px, 1.3vw, 12px);
        margin-bottom: 4px;
      }

      .user-wrapper {
        cursor: pointer;
        transition: 0.3s;
        padding: clamp(5.5px, .3vw, 8px) 0 !important;
        & > svg {
          margin-right: 10px;
          width: clamp(16px, 1.2vw, 20px);
          height: clamp(16px, 1.2vw, 20px);
        }
        &:hover {
          transition: all 0.2s ease-out;
          -webkit-transition: all 0.2s ease-out;
          -moz-transition: all 0.2s ease-out;
          -o-transition: all 0.2s ease-out;
          padding: clamp(5.5px, .3vw, 8px) 7px !important;
          border-radius: 10px;
          background-color: $primary-bg;
        }
      }

      .active {
        outline: none;
        transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        padding: 7px;
        border-radius: 10px;
        background-color: $primary-bg;
      }
      .detail-wrapper-transaction {
        flex: 1;
        position: relative;
        @extend .custom-scrollbar;
        // max-height: 74vh;
        // height: 100vh;
        border-radius: 7px;
        background-color: $secondary-bg;
        .detail-content {
          padding: 0 clamp(12px, 1vw, 15px);
          .icon-detail {
            height: 20px;
            width: 20px;
            border-radius: 10px;
            background-color: $primary-text;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            margin-top: 8px;
          }

          .ea_table {
            display: flex;
            flex-direction: column;
            background: $secondary-bg;
            color: $primary-text;
            overflow: hidden;
            box-sizing: border-box;
            float: left;
            height: auto;
            min-width: 100%;
            width: 100%;
            height: 100%;
          }

          .ea_table tbody,
          thead {
            flex-direction: column;
            display: flex;
            white-space: nowrap;
          }

          .ea_table tbody {
            @extend .custom-scrollbar;
            // height: calc(100vh - 11.4rem) !important;
            height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: $primary-bg;
            border-radius: 7px;
            & > .active {
              margin-right: -7px;
            }
          }

          .ea_table thead {
            text-overflow: ellipsis;
            padding: .8rem calc(7px + .5rem) .5rem .5rem;
          }

          .ea_table tr {
            width: inherit;
            display: flex;
            border-radius: 0;
            border-bottom: 1px solid $secondary-bg;
          }

          .on-select {
            // background-color: $secondary-bg;
          }
          .ea_table td {
            white-space: normal;
            width: inherit;
            // height: 25px;
            padding: clamp(4px, .4vw, 10px) 0;
            display: flex;
            align-items: flex-start;
            flex: 2;
            word-break: break-word;
          }
          .ea_table th {
            display: flex;
            flex: 2;
            font-weight: 400;
          }
          .icon-gap {
            gap: 20%;
          }
          .status-select {
            & > div {
              right: calc(17% + -1px);
              position: relative;
            }
          }

        }
      }
    }
    .responsive-font {
      font-size: 1rem;
    }
    .scroll {
      background-color: $secondary-bg;
      .simplebar-content-wrapper {
        height: 100%;
        
        & > .simplebar-content {
          position: relative;
          height: calc(100% - 1rem);
        }
      }
    }
    .infinite-scroll-component__outerdiv {
      height: 100%;
      flex-grow: 1;
      position: relative;
      .user-list-wrap {
        padding: 13px 13px 0 13px;
        background-color: $primary-bg;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        position: absolute;
        width: 100%;
      }
    }
  }

  .row {
    width: auto;
  }
  .flex-grow-2 {
    flex-grow: 2 !important;
  }
  .flex-grow-3 {
    flex-grow: 3 !important;
  }
  .flex-grow-4 {
    flex-grow: 4 !important;
  }
  .z-10 {
    z-index: 10;
  }
}
  .modal-bet-transaction-table {
    min-height: calc(25px + 3vh);;
    .detail-content {
      .ea_table {
        display: flex;
        flex-direction: column;
        background: $secondary-bg;
        color: $primary-text;
        overflow: hidden;
        box-sizing: border-box;
        float: left;
        height: auto;
        min-width: 100%;
        width: 100%;
        height: 100%;
      }

      .ea_table tbody,
      thead {
        flex-direction: column;
        display: flex;
        white-space: nowrap;
      }

      .ea_table tbody {
        // height: calc(100vh - 11.4rem) !important;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        background-color: $primary-bg;
        border-radius: 7px;
        & > .active {
          margin-right: -7px;
        }
      }

      .ea_table thead {
        text-overflow: ellipsis;
        padding: .8rem 7px .5rem .5rem;
      }

      .ea_table tr {
        width: inherit;
        display: flex;
        border-radius: 0;
        width: 100%;
      }

      .on-select {
        background-color: $secondary-bg;
      }
      .ea_table td {
        white-space: normal;
        width: inherit;
        // height: 25px;
        padding: clamp(4px, .3vw, 10px) 0;
        display: flex;
        align-items: flex-start;
        flex: 2;
        word-break: break-word;
      }
      .ea_table th {
        display: flex;
        flex: 2;
        font-weight: 400;
        padding: inherit;
      }
      .icon-gap {
        gap: 20%;
      }
      .status-select {
        right: calc(1% + 2px);
        position: relative;
      }

    }
  }