@import "assets/stylesheets/colors";

.topic-content {
    padding: 20px 0 20px 0;
    border-radius: 10px;
    background-color: $primary-bg;
  }

  .responsive-font {
    font-size: 1rem
  }

  .copy:hover {
    opacity: 0.7;
  }

  .iconAff{
    height: 18px;
    width: 18px;
  }

  .map-form {
    display: flex;
    .map-icon {
        width: 10px;
        font-size: 15px;
    }
}