@import "assets/stylesheets/colors";
@import "assets/stylesheets/typography";

.input-text {
  position: relative;

  label {
    -webkit-text-fill-color: $primary-text;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: $primary-text;
    box-shadow: 0 0 0px 1000px $secondary-bg inset;
  }
  input::-webkit-input-placeholder {
    line-height: 28px;
  }

  input.input-core {
    outline: none;
    border: none;
    width: 100%;
    background-color: transparent;
    padding-right: 0;
    margin-top: -1px;
    line-height: inherit;
    @extend .body-1;
    line-height: 28px;

    &:focus {
      & ~ .input-underline {
        // border-color: $secondary-bg;
      }
    }

    &::placeholder {
      color: $secondary-text;
    }

    &:disabled {
      background-color: transparent;
      color: $secondary-text;
      opacity: .4;
      & ~ .input-underline {
        border-color: $secondary-text;
      }
    }
  }

  .input-underline {
    width: 100%;
    height: 0;
    border-top: solid 1px $secondary-text;
    padding-bottom: 1px;
    right: 0px;
    position: relative;
  }

  .input-error-message {
    @extend .subtitle-2;
    padding-top: 4px;
    min-height: 24px;
    text-align: left;
    pointer-events: none;
    color: $primary-red;
    visibility: hidden;
  }

  &.error {
    .input-underline {
      border-color: $primary-red;
    }
    .input-error-message {
      visibility: visible;
    }
  }
}