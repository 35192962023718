@import "assets/stylesheets/colors";

.login-container {
  background-color: $primary-bg;
  .content-wrapper {
    height: 100vh;
    .centered {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      .card-wrapper {
        height: fit-content;
        .card-box {
          width: fit-content;
          padding: 40px;
          .headding-wrapper {
            font-size: 2.2rem;
          }
        }
      }
    }
  }
}
