@import "assets/stylesheets/colors";
.date-time-range-picker {
  font-family: "Roboto";
  width: fit-content;
  .react-datetimerange-picker {
    display: inline-flex;
    position: relative;
    background: $primary-bg;
    color: $primary-text;
    border-radius: 10px;
  }
  .react-datetimerange-picker,
  .react-datetimerange-picker *,
  .react-datetimerange-picker *:before,
  .react-datetimerange-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-datetimerange-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-datetimerange-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    border: 0;
  }
  .react-datetimerange-picker__inputGroup {
    min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
    height: 100%;
    flex-grow: 1;
    padding: 0 2px;
  }
  .react-datetimerange-picker__inputGroup__divider {
    padding: 1px 0;
    color: $primary-text;
    background: $secondary-bg;
  }
  .react-datetimerange-picker__inputGroup__input {
    min-width: 0.54em;
    height: calc(100% - 2px);
    position: relative;
    padding: 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
    color: $primary-text;
    background: $primary-bg;
    &:hover {
        color: $secondary-red;
        transition: ease-in-out 300ms;
    }
    option {
        color: $primary-text;
        &:disabled {
          color: $secondary-red;
        }
      }
  }
  .react-datetimerange-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-datetimerange-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-datetimerange-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-datetimerange-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px + 0.54em);
  }
  .react-datetimerange-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-datetimerange-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-datetimerange-picker__button:enabled {
    cursor: pointer;
  }
  .react-datetimerange-picker__button:enabled:hover
    .react-datetimerange-picker__button__icon,
  .react-datetimerange-picker__button:enabled:focus
    .react-datetimerange-picker__button__icon {
    stroke: $primary-red;
    transition: ease-in-out 300ms;
  }
  .react-datetimerange-picker__button:disabled
    .react-datetimerange-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-datetimerange-picker__button svg {
    display: inherit;
    stroke: $primary-text;
    transition: ease-in-out 300ms;
  }
  .react-datetimerange-picker__calendar,
  .react-datetimerange-picker__clock {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-datetimerange-picker__calendar--closed,
  .react-datetimerange-picker__clock--closed {
    display: none;
  }
  .react-datetimerange-picker__calendar {
    width: 350px;
    max-width: 100vw;
  }
  .react-datetimerange-picker__calendar .react-calendar {
    border-width: thin;
  }
  .react-datetimerange-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
  }

  .react-calendar {
    width: 350px;
    max-width: 100%;
    background: $secondary-bg;
    color: $primary-text;
    border: none;
    box-shadow: black 6px 10px 80px -10px;
    font-family: Roboto, Helvetica, sans-serif;
    line-height: 1.125em;

  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }

  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
    transition: ease-in-out 150ms;
  }
  .react-calendar__navigation {
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
    font-weight: bold;
    color: $primary-text;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: $primary-red;
    font-weight: bold;
    color: $primary-text;
  }
  .react-calendar__navigation button[disabled] {
    background-color: $secondary-bg;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers {
    font-weight: bold;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  }
  .react-calendar__month-view__days__day--weekend {
    color: $primary-red;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__month-view__days__day {
    font-weight: bold;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    font-weight: bold;
    max-width: 100%;
    text-align: center;
    padding: 0.75em 0.5em;
    background: none;
    background-color: $secondary-text;
  }
  .react-calendar__tile:disabled {
    background-color: $secondary-bg;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: $secondary-red;
    color: $primary-text;
    transition: ease-in-out 150ms;

  }
  .react-calendar__tile--now {
    background: $primary-bg;
    color: $primary-text;
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: $primary-bg;
    color: $primary-text;
  }
  .react-calendar__tile--hasActive {
    background: $secondary-red;
    color: $primary-text;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    background: $primary-red;
    color: $primary-text;
    transition: ease-in-out 150ms;
  }
  .react-calendar__tile--active {
    background: $primary-red;
    color: $primary-text;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    color: $primary-text;
    background: $primary-red;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: $secondary-red;
    color: $primary-text;
    transition: ease-in-out 150ms;
  }

  //   .react-datetimerange-picker {
  //     border-radius: 10px;
  //     background-color: $secondary-bg;
  //     color: $primary-text;
  //     .react-datetimerange-picker__wrapper {
  //       &:disabled {
  //         color: $tertiary-text;
  //       }
  //       .react-datetimerange-picker__inputGroup__input {
  //         color: $primary-text;
  //         background-color: $secondary-bg;
  //         transition: ease-in-out 0.3s;
  //         &:hover {
  //           color: red;
  //           transition: ease-in-out 0.3s;
  //           cursor: pointer;
  //         }
  //         &:disabled {
  //           color: $tertiary-text;
  //           cursor: unset;
  //         }
  //         option {
  //           color: $primary-text;
  //           &:disabled {
  //             color: $secondary-red;
  //           }
  //         }
  //       }
  //       button.react-datetimerange-picker__button {
  //         .react-datetimerange-picker__button__icon {
  //           cursor: pointer;
  //           stroke: $primary-text;
  //           transition: ease-in-out 0.3s;
  //           &:disabled {
  //             stroke: $tertiary-text;
  //           }
  //           &:hover {
  //             stroke: $primary-red;
  //             transition: ease-in-out 0.3s;
  //           }
  //         }
  //       }
  //     }

  //     .react-datetimerange-picker__calendar > .react-calendar {
  //       font: inherit;
  //       background-color: $secondary-bg;
  //       color: $primary-text;
  //       &:disabled {
  //         background-color: $secondary-bg;
  //         color: $primary-text;
  //       }
  //       .react-calendar__tile {
  //         font-weight: bold;
  //         color: $primary-text;
  //         background-color: $secondary-bg;
  //         &:disabled {
  //           color: $primary-bg;
  //           background-color: $secondary-bg;
  //         }
  //       }
  //       .react-calendar__month-view__days__day {
  //         background-color: $secondary-bg;
  //         &:hover {
  //           background-color: $primary-red;
  //           color: $primary-text;
  //         }
  //       }
  //       .react-calendar__month-view__days__day--weekend {
  //         color: $primary-red !important;
  //       }
  //       .react-calendar__month-view__days__day--neighboringMonth {
  //         color: $primary-text;
  //       }
  //       .react-calendar__navigation {
  //         .react-calendar__navigation__label {
  //           background-color: $secondary-bg;
  //           color: $primary-text;
  //           &:hover {
  //             background-color: $primary-red;
  //           }
  //           &:disabled {
  //             background-color: $secondary-bg;
  //           }
  //         }
  //         .react-calendar__navigation__arrow {
  //           background-color: $secondary-bg;
  //           color: $primary-text;
  //           font-weight: bold;
  //           &:hover {
  //             background-color: $primary-red;
  //           }
  //           &:disabled {
  //             color: $secondary-bg;
  //             background-color: $secondary-bg;
  //           }
  //         }
  //       }
  //       .react-calendar__viewContainer {
  //         font-weight: bold;
  //         color: $primary-text;
  //         background-color: $secondary-bg;
  //         &:disabled {
  //           background-color: $secondary-bg;
  //         }
  //       }
  //     }
  //   }
}
