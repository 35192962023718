.hide {
    visibility: hidden;
}

.map-form {
    display: flex;
    .map-icon {
        width: 10px;
        font-size: 15px;
    }
}