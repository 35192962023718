@import "assets/stylesheets/colors";

.button-container {
  cursor: pointer;
  height: 40px;
  border-radius: 20px;
  transition: all 256ms ease-in-out;
  border: none;
  outline: none !important;

  display: flex;
  align-items: center;
  justify-content: center;

  *:focus {
    outline: none;
  }

  &.rectangle {
    background-color: $primary-green;
    border-radius: 4px !important;

    &:hover {
      background-color: $secondary-green;
    }
  }

  &.normal {
    background-color: $primary-green;

    &:hover {
      background-color: $secondary-green;
    }
  }

  &.outline-default {
    border: solid 1px $primary-text;
    background-color: transparent !important;
  }

  &.outline {
    border: solid 1px $primary-green;
    background-color: transparent;

    .button-text {
      color: $primary-green;
    }

    &:hover {
      background-color: $primary-green;

      .button-text {
        color: $primary-text;
      }
    }
  }

  &.small {
    height: 26px;
    border-radius: 13px;
  }

  &.medium {
    height: 40px;
    border-radius: 20px;
  }

  &.large {
    height: 44px;
    border-radius: 22px;
  }

  .button-icon {
    width: 24px;
    height: 24px;
  }

  .button-text {
    text-align: center;
  }

  &.disabled {
    opacity: 0.3;
    cursor: no-drop !important;

    &.normal, &.rectangle {
      &:hover {
        filter: unset;
      }
    }

    &.outline {
      &:hover {
        background-color: transparent;

        .button-text {
          color: $primary-green;
        }
      }
    }
  }
}
