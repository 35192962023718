@import 'assets/stylesheets/typography';
@import 'assets/stylesheets/colors';

.input-checkbox {
  transition-duration: .09s;
  transition-timing-function: cubic-bezier(0, 0.83, 0.58, 1);
  &.text-in-box {
    padding: 0;
    border-radius: 5px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-white;
    border: 1px solid;
    margin: 0;
    & > .checkbox-label{
      @extend .input-text-scale;
    }
    & > .checkbox-checkmark {
      display: none
    }
  }
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover .core-checkbox ~ .checkbox-checkmark {
    background-color: #ccc;
  }

  .core-checkbox {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ .checkbox-checkmark {
      background-color: $primary-green;
    }

    &:checked ~ .checkbox-checkmark::after {
      display: block;
    }
  }

  .checkbox-checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 16px;
    width: 16px;
    border-radius: 3px;
    background-color: #eee;

    &::after {
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $primary-text;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);

      content: "";
      position: absolute;
      display: none;
    }
  }

  .checkbox-label {
    @extend .subtitle-1;
    text-align: left;
    color: $primary-white;
  }
}
