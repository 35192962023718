@import "assets/stylesheets/colors";

.back-button-container {
  .back-text {
    font-family: "Roboto-bold", "SukhumvitSet-bold";
    font-size: 14px;
    line-height: 16px;
    &.scale {
      font-size: clamp(11px, 1.3vw, 16px);
      line-height: clamp(13px, calc(1.5vw + 2px), 18px);
    }
    cursor: pointer;
  }
  .title-text {
    margin-left: 1em;
    font-family: "Roboto-Medium", "SukhumvitSet-text";
    font-size: 14px;
    line-height: 16px;
    color: $primary-text;
    &.scale {
      font-size: clamp(14px, 1.7vw, 22px);
      line-height: clamp(16px, calc(1.7vw + 2px), 24px);
    }
  }
}
