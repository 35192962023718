@import "assets/stylesheets/colors";

.icon-button-container {
  background-color: transparent;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  transition: all 125ms ease-in;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: rgba($color: $primary-text, $alpha: 0.4);
    &.disable-hover {
      background-color: #ffffff00;
    }
  }

  .icon-button {
    width: 16px;
    height: 16px;
    object-fit: contain;
    fill: $primary-text;
    color: $primary-text;
  }
}
