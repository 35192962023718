@import "assets/stylesheets/typography";
@import "assets/stylesheets/colors";

.date-picker-container {
  border-radius: 5px;
  input {

    // font-family: "Plus Jakarta Text";
    // font-size: 16px;
    // font-weight: normal;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: 1.38;
    width: 100%;
    padding: 0 15px 0 0px;
    margin: 0;
    text-align: center;
    letter-spacing: normal;
    color: $primary-white;
    border-radius: 16px;
    outline: none;
    border: none;
    background-color: $primary-bg;
    height: 100%;
    &::placeholder {
      @extend .subtitle-5, .subtitle-5.scale;
      color: $secondary-white
    }
    @extend .subtitle-5, .subtitle-5.scale;
    color: $secondary-white
  }

  // input:disabled {
  //   background-color: $secondary-bg;
  // }

  .rmdp-container {
    width: 100%;
    height: 100% !important;
    & > div {
      height: 100%;
      & > div {
        height: 100%;
      }
    }
    .rmdp-input {
      position: absolute;
      &.error {
        background-color: rgba($primary-red, 0.05);
      }

      &.disabled {
        cursor: no-drop;
        pointer-events: none;
        background-color: $secondary-bg;
      }

      &.placeholder {
        color: $primary-white ;
      }
    }

    .icon {
      color: $primary-white;
      width: clamp(12px, 1.3vw, 20px);
      stroke-width: 2px;
    }
    .rmdp-wrapper {
      position: fixed;
      // bottom: calc(var(--vh, 1vh) * -20);
      @media only screen and (max-height: 700px) {
        transform: scale(.8);
        transform-origin: left top;
        bottom: calc(var(--vh, 1vh) * -48);
      }
      @media only screen and (max-height: 368px) {
        transform: scale(.8);
        transform-origin: left top;
        bottom: calc(var(--vh, 1vh) * -45);
      }
      @media only screen and (max-height: 280px) {
        transform: scale(.6);
        transform-origin: left top;
        bottom: calc(var(--vh, 1vh) * -65);
      }
    }
  }


  .rmdp-input:focus {
    border: 0px solid var(--background-gray);
    box-shadow: 0 0 0px var(--background-gray);
    outline: none !important;
  }

  .rmdp-day.rmdp-today span {
    background-color: $tertiary-purple  !important;
    color: var(--black-00);
  }
  .rmdp-day.rmdp-deactive {
    color: #000000a4;
  }
  .rmdp-day.rmdp-disabled {
    color: #00000050;
  }

  // .rmdp-day.rmdp-selected span:not(.highlight) {
  //   background-color: #7fdbff;
  //   color: var(--black-00);
  // }

  .input-error-message {
    @extend .body-1;
    min-height: 24px;
    color: var(--red);
    font-size: 12.8px;
    line-height: 18px;
  }

  .rmdp-toolbar {
    justify-content: flex-end !important;

    div {
      background-color: $transparent !important;
      color: $primary-purple;
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
      flex-basis: unset;
      margin-right: 20px;

      &:hover {
        text-decoration: underline;
        border: none !important;
        box-shadow: none !important;
      }
    }

  }

  --rmdp-primary-yellow: #845bdd;
  --rmdp-hover-yellow: #845BDD;
  --rmdp-today-yellow: rgba(#dff5eb, 0.5);
  --rmdp-secondary-yellow: #00000050;
}