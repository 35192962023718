$primary-bg: #252525;
$secondary-bg: #353a40;
$tertiary-bg: #2C2E32;

$primary-text: #f7f7f7;
$secondary-text: #c5c5c5;
$tertiary-text: #A7ABB7;
$gray-text: #919191;

$primary-purple: #714BDA;
$secondary-purple: #845BDD;
$tertiary-purple: #E2DBF7;


$primary-green: #6CCFB8;
$secondary-green: #71CE99;
$tertiary-green: #DFF5EB;
$quaternary-green: #27A02C;

$primary-red: #f4263d;
$secondary-red: #f65163;
$tertiary-red: #DD3E45;
$quaternary-red: #D32F2F;

$primary-white: #ffffff;
$secondary-white: #A8ABB6;;

$primary-orange: #FF9800;

$transparent: transparent;
$primary-black: #000000;

//BG
.primary-bg {
    background-color: $primary-bg !important;
}
.secondary-bg {
    background-color: $secondary-bg !important;
}
.tertiary-bg {
    background-color: $tertiary-bg !important;
}
.primary-red-bg {
    background-color: $primary-red !important;
}
.secondary-red-bg {
    background-color: $secondary-red !important;
}
.tertiary-red-bg {
    background-color: $tertiary-red !important;
}
.quaternary-red-bg {
    background-color: $quaternary-red !important;
}
.primary-green-bg {
    background-color: $primary-green !important;
}
.secondary-green-bg {
    background-color: $secondary-green !important;
}
.quaternary-green-bg {
    background-color: $quaternary-green !important;
}
.primary-white-bg {
    background-color: $primary-white !important;
}
.secondary-white-bg {
    background-color: $secondary-white !important;
}
.primary-orange-bg {
    background-color: $primary-orange !important;
}
.primary-transparent-bg {
    background-color: $transparent !important;
}
.primary-black-bg {
    background-color: $primary-black !important;
}
//TEXT
.primary-text {
    color: $primary-text !important;
}
.secondary-text {
    color: $secondary-text !important;
}
.tertiary-text {
    color: $tertiary-text !important;
}
.gray-text {
    color: $gray-text !important;
}
.primary-purple-text {
    color: $primary-purple !important;
}
.secondary-purple-text {
    color: $secondary-purple !important;
}
.tertiary-purple-text {
    color: $tertiary-purple !important;
}
.primary-green-text {
    color: $primary-green !important;
}
.secondary-green-text {
    color: $secondary-green !important;
}
.tertiary-green-text {
    color: $tertiary-green !important;
}
.quaternary-green-text {
    color: $quaternary-green !important;
}
.primary-red-text {
    color: $primary-red !important;
}
.secondary-red-text {
    color: $secondary-red !important;
}
.tertiary-red-text {
    color: $tertiary-red !important;
}
.quaternary-red-text {
    color: $quaternary-red !important;
}
.primary-white-text {
    color: $primary-white !important;
}
.secondary-white-text {
    color: $secondary-white !important;
}
.primary-orange-text {
    color: $primary-orange !important;
}
.primary-transparent-text {
    color: $transparent !important;
}
.primary-black-text {
    color: $primary-black !important;
}