@import "assets/stylesheets/colors";

.base-input {
    border: none;
    width: 100%;
    border: 2px solid;
    // box-sizing: border-box;
    border-radius: 4px;
    color:$secondary-text;
    font-size: large;
}

.base-input:focus{
    // border-color:#ff0000;
    outline: none !important;
}

::placeholder { /* Most modern browsers support this now. */
    color:$tertiary-text;
    font-size: medium;
}

.moveIn-label{
    animation: label-move-in 0.5s;
}
.moveOut-label{
    animation: label-move-out 0.5s;
}

.label-hide{
    position: relative;
    opacity: 0;
}
.label-show{
    position: relative;
    font-size: medium;
    opacity: 1;
}


@keyframes label-move-in {
    from {
        opacity: 0;
        top: 23px;
    }

    to {
        opacity: 1;
        top: 0px;
    }
}
@keyframes label-move-out {
    to {
        opacity: 0;
        top: 23px;
    }

    from {
        opacity: 1;
        top: 0px;
    }
}

// ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
//     color:    #909;
// }
// :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
//     color:    #909;
//     opacity:  1;
// }
// ::-moz-placeholder { /* Mozilla Firefox 19+ */
//    color:    #909;
//    opacity:  1;
// }
// :-ms-input-placeholder { /* Internet Explorer 10-11 */
//    color:    #909;
// }
// ::-ms-input-placeholder { /* Microsoft Edge */
//    color:    #909;
// }
