@import "assets/stylesheets/colors";

.box-shadow{
    box-shadow: 0px 5px 10px 6px rgba(34, 0, 34, 0.1);
    border-radius: 4px;
    background-color:$primary-bg;
    transition: .3s;
    cursor: pointer;
    &:hover {
        filter:brightness(93%);
        transition: .3s;
    }
    .header-text {
        color: $primary-red;
        filter: contrast(80%);
    }
    .desc-text {
        color: $primary-text;

    }
}