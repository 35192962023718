@import "assets/stylesheets/colors";
@import 'assets/stylesheets/typography';

.selector-item-container {
    // height: 60px;
    padding-left: calc(6% + 3px);
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    // background-color: $primary-bg;
    border-radius: inherit;
    &:hover {
        background-color: $primary-red;
    }

    &.selected {
        background-color: $primary-red;
    }

    &.displaying {
        background-color: transparent;
        .selector-text-wrapper {
            border-bottom: unset;
        }
        &:hover {
            background-color: unset;
        }
    }
    .selector-icon-wrapper {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        margin: auto 0 auto 14px;
    }

    .selector-text-wrapper {
        h5 {
            font-size: inherit;
            white-space: nowrap;
        }
        padding: 5px 0 5px 0;
        align-items: center;
        flex: 1;
        height: 100%;
        display: flex;
        // margin-left: 18px;
        justify-content: center;
        flex-direction: column;
        border-bottom: solid 1px rgba($color: $secondary-text, $alpha: 0.1);
    }


}
