@import "assets/stylesheets/colors";
@import "assets/stylesheets/fonts";


.navbar-container {
  top: 26px;
  height: 40px;
  background-color: $primary-bg;
  box-shadow: unset;
  z-index: 3;

  &.non-authorized {
    display: none;
  }

  .container-fluid {
    display: flex;
    flex-direction: row;

    .leading-navbar-container {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
      cursor: pointer;
      min-width: 0;
      flex: 0;

      .logo-container {
        height: 100%;
      }

      .name-container {
        font-family: "Roboto-Bold";
        color: $tertiary-red;
        span {
          color: $primary-white;
          font-size: .9em;
          font-family: "Roboto-Light";
          position: relative;
          top: 1px;
        }
      }

      h4 {
        white-space: nowrap;
      }
    }

    .trailing-navbar-container {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .burger-container {
        cursor: pointer;
        color: $primary-text;
        margin-left: 12px;
        width: 24px;
        height: 24px;
      }
    }
  }

  &.light-mode {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: $primary-text;

    .container>.leading-navbar-container>.name-container {
      color: $primary-text;

      span {
        color: $primary-green;
      }
    }

    .container>.trailing-navbar-container>.burger-container {
      color: $primary-text;
    }
  }

  &.dark-mode {
    box-shadow: unset;
    background-color: $tertiary-bg;

    .container>.leading-navbar-container>.name-container {
      color: $primary-red;

      span {
        color: $primary-text;
      }
    }

    .container>.trailing-navbar-container>.burger-container {
      color: $primary-text;
    }
  }
}

.credit-badge-wrapper {
  border-radius: 16px;
  background-image: $primary-green;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 14px;

  &.light-mode {
    background-image: $primary-green;
  }

  &.dark-mode {
    background-image: unset;
    background-color: $secondary-bg;
  }
}