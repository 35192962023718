@import "assets/stylesheets/colors";

.page-container {
  .content-wrapper {
    height: fit-content;
    .search-wrapper {
      .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .partner-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        padding: 7px;
        border-radius: 10px;
        background-color: $primary-bg;
      }
    }
    .active {
      outline: none;
      transition: all 0.2s ease-out;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      padding: 7px;
      border-radius: 10px;
      background-color: $primary-bg;
    }
    .detail-wrapper {
      //   .topic-content {
      //     padding: 20px 0 20px 0 ;
      //     border-radius: 10px;
      //     background-color: $primary-bg;
      //   }
      .detail-content {
        .vendor-img {
          box-sizing: border-box;
          .vendor-name {
            .badge:hover {
              cursor: pointer;
              border: 2px solid $primary-red;
              transform: scale(1.1); 
            }
          }
          .img-box {
            position: relative;
            box-shadow: 0px 0px 30px 10px $primary-bg;
            border-radius: 15px;
            max-width: 200px;
            min-width: 180px;
            max-height: 200px;
            min-height: 180px;
            width: 15vw;
            flex: 1;
            align-items: center;
            justify-content: center;
          }
          img {
            width: 100%;
            object-fit: contain;
            height: 100%;
            position: absolute;
            padding-bottom: 0.5rem;
          }
          .img-select {
            -webkit-filter: grayscale(100%);
            -moz-filter: grayscale(100%);
            -ms-filter: grayscale(100%);
            filter: grayscale(100%);
            filter: gray; /* IE 6-9 */
            transition: ease-in-out 300ms;
          }
        }
        .icon-detail {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          background-color: $primary-text;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          margin-top: 8px;
        }
        .date-row {
          display: inline-flex;
          align-items: baseline;
        }
      }
    }
  }
  .responsive-font {
    font-size: 1rem;
  }
  .badge {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    padding: 3px 6px;
    border: 2px solid $tertiary-text;
    min-width: 10px;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 99999px;
    transition: all .2s ease-in-out;
  }
  @media (max-width: 720px) {
    .vendor-img {
      display: flex;
      justify-content: center;
      align-self: center;
    }
    .vendor-name {
      text-align: center;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-animate {
  img {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
  }
}
