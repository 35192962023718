.modal-content {
  background-color: unset !important;
  border: unset !important;
  border-radius: unset !important;
  align-items: center;
  & > div {
    width: 100%;
  }
}

.modal-container-dialog {
  &.MODAL_OVER {
    z-index: 1053 !important;
  }
}

.modal-custom-dialog {
  visibility: hidden;
  opacity: 0;
  transition: opacity 256ms ease-in, visibility 256ms ease-in;

  &.show {
    visibility: visible;
    opacity: 1;
    margin: auto;
    height: 100%;
  }

  &.hide {
    visibility: hidden;
    opacity: 0;
  }
}

.modal-backdrop {
  visibility: hidden;
  opacity: 0 !important;
  transition: opacity 256ms ease-in, visibility 256ms ease-in !important;

  &.MODAL_OVER {
    z-index: 1052 !important;
  }

  &.visible {
    opacity: 0.5 !important;
  }

  &.hiden {
    opacity: 0 !important;
  }
}
