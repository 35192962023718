.lotto-card-container {
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 12px;
  
    .flag-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  
    .lotto {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 3px;
    }
  }
  