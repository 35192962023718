@import 'assets/stylesheets/colors';
@import 'assets/stylesheets/scrollbars';

.page-container {
  .content-wrapper {
    height: fit-content;

    .search-wrapper {
      .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .user-wrapper {
      cursor: pointer;
      transition: 0.3s;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      &:hover {
        transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        padding: 7px;
        border-radius: 10px;
        background-color: $primary-bg;
      }
    }

    .active {
      outline: none;
      transition: all 0.2s ease-out;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      padding: 7px;
      border-radius: 10px;
      background-color: $primary-bg;
    }

    .detail-wrapper {
      @extend .custom-scrollbar;
      overflow-y: auto;
      max-height: 74vh;
      height: fit-content;

      .detail-content {
        .icon-detail {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          background-color: $primary-text;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          margin-top: 8px;
        }
      }
    }
  }
  .affilate-section {
    .affilate-host {
      max-height: 50vh;
      height: fit-content;
      overflow-x: auto;
      @extend .custom-scrollbar;
    }
  }
  .responsive-font {
    font-size: 1rem;
  }
}
