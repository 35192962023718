@import "../fonts/Roboto-Bold.ttf";
@import "../fonts/Roboto-Light.ttf";
@import "../fonts/Roboto-Medium.ttf";
@import "../fonts/Roboto-Thin.ttf";
@import "../fonts//Roboto-Regular.ttf";

@import "../fonts/SukhumvitSet-Bold.ttf";
@import "../fonts/SukhumvitSet-Light.ttf";
@import "../fonts/SukhumvitSet-Medium.ttf";
@import "../fonts/SukhumvitSet-SemiBold.ttf";
@import "../fonts/SukhumvitSet-Text.ttf";
@import "../fonts/SukhumvitSet-Thin.ttf";

@font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto-Bold"), url("../fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Light";
    src: local("Roboto-Light"), url("../fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Medium";
    src: local("Roboto-Medium"), url("../fonts/Roboto-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Thin";
    src: local("Roboto-Thin"), url("../fonts/Roboto-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"), url("../fonts/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SukhumvitSet-Bold";
  src: local("SukhumvitSet-Bold"), url("../fonts/SukhumvitSet-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SukhumvitSet-Light";
  src: local("SukhumvitSet-Light"), url("../fonts/SukhumvitSet-Light.ttf") format("truetype");
}

@font-face {
  font-family: "SukhumvitSet-Medium";
  src: local("SukhumvitSet-Medium"), url("../fonts/SukhumvitSet-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SukhumvitSet-SemiBold";
  src: local("SukhumvitSet-SemiBold"), url("../fonts/SukhumvitSet-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "SukhumvitSet-Text";
  src: local("SukhumvitSet-Text"), url("../fonts/SukhumvitSet-Text.ttf") format("truetype");
}

@font-face {
  font-family: "SukhumvitSet-Thin";
  src: local("SukhumvitSet-Thin"), url("../fonts/SukhumvitSet-Thin.ttf") format("truetype");
}
