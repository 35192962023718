@import "./colors.scss";

.force-unset-headline {
  margin: unset;
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  font-weight: unset;
}

h1 {
  font-family: "Roboto-Bold";
  font-size: 56px;
  line-height: 64px;
  color: $primary-text;
  @extend .force-unset-headline;
}

h2 {
  font-family: "Roboto-Bold", "SukhumvitSet-bold";
  font-size: 26px;
  line-height: 30px;
  color: $primary-text;
  @extend .force-unset-headline;
}

h3 {
  font-family: "Roboto-Regular";
  font-size: 22px;
  line-height: 26px;
  color: $primary-text;
  @extend .force-unset-headline;
}

h4 {
  font-family: "Roboto-Bold", "SukhumvitSet-light";
  font-size: 18px;
  line-height: 22px;
  color: $primary-text;
  @extend .force-unset-headline;
  &.scale {
    font-size: clamp(16px, 1.7vw, 20px);
    line-height: clamp(18px, calc(1.7vw + 2px), 22px);
  }
}

h5 {
  font-family: "Roboto-Regular", "SukhumvitSet-medium";
  font-size: 16px;
  line-height: 20px;
  color: $primary-text;
  @extend .force-unset-headline;
  &.scale {
    font-size: clamp(14px, 1.7vw, 18px);
    line-height: clamp(16px, calc(1.7vw + 2px), 20px);
  }
}

h6 {
  font-family: "Roboto-Bold", "SukhumvitSet-bold";
  font-size: 14px;
  line-height: 18px;
  color: $primary-text;
  @extend .force-unset-headline;
  &.scale {
    font-size: clamp(14px, 1.7vw, 18px);
    line-height: clamp(16px, calc(1.7vw + 2px), 20px);
  }
}

.body-1 {
  font-family: "Roboto-Regular", "SukhumvitSet-thin";
  font-size: 18px;
  line-height: 20px;
  color: $primary-text;
}

.body-2 {
  font-family: "Roboto-Regular", "SukhumvitSet-thin";
  font-size: 16px;
  line-height: 18px;
  color: $primary-text;
  &.scale {
    font-size: clamp(16px, 1.7vw, 18px);
    line-height: clamp(18px, calc(1.7vw + 2px), 20px);
  }
}

.subtitle-1 {
  font-family: "Roboto-Regular", "SukhumvitSet-text";
  font-size: 16px;
  line-height: 18px;
  color: $primary-text;
  &.scale {
    font-size: clamp(16px, 1.7vw, 18px);
    line-height: clamp(18px, calc(1.7vw + 2px), 20px);
  }
}

.subtitle-2 {
  font-family: "Roboto-Medium", "SukhumvitSet-text";
  font-size: 14px;
  line-height: 16px;
  color: $primary-text;
  &.scale {
    font-size: clamp(14px, 1.5vw, 16px);
    line-height: clamp(16px, calc(1.5vw + 2px), 18px);
  }
}
.subtitle-3 {
  font-family: "Roboto-Medium", "SukhumvitSet-light";
  font-size: 12px;
  line-height: 14px;
  color: $primary-white;
  &.scale {
    font-size: clamp(11px, 1.3vw, 16px);
    line-height: clamp(14px, calc(1.3vw + 2px), 18px);
  }
}
.subtitle-4 {
  font-family: "Roboto-Medium", "SukhumvitSet-text";
  font-size: 10px;
  line-height: 12px;
  color: $primary-white;
  &.scale {
    font-size: clamp(10px, 1.3vw, 16px);
    line-height: clamp(12px, calc(1.3vw + 2px), 18px);
  }
}
.subtitle-5 {
  font-family: "Roboto-Medium", "SukhumvitSet-text";
  font-size: 7px;
  line-height: 12px;
  color: $primary-white;
  &.scale {
    font-size: clamp(7px, calc(.9vw - 2px), 12px);
  }
}
.table-text {
  & > thead {
    font-family: "Roboto-Regular", "SukhumvitSet-thin";
    font-size: 14px;
    line-height: 12px;
    color: $primary-white;
  }
  & > tbody {
    font-family: "Roboto-Regular", "SukhumvitSet-thin";
    font-size: 12px;
    line-height: 12px;
    color: $primary-white;
  }
  &.scale {
    & > thead {
      font-size: clamp(9px, 1.2vw, 14px);
      color: $primary-white;
    }
    & > tbody {
      font-size: clamp(7px, .8vw, 14px);
      line-height: clamp(9px, calc(.8vw + 2px), 22px);
      color: $primary-white;
      .date-text {
        font-size: clamp(6px, .8vw, 14px);
        white-space: nowrap !important;
      }
    }
  }
  &.scale-modal {
    & > thead {
      font-size: clamp(10px, 1.2vw, 14px);
      color: $primary-white;
    }
    & > tbody {
      font-size: clamp(9px, 1.25vw, 14px);
      line-height: clamp(14px, calc(1.2vw + 2px), 22px);
      color: $primary-white;
    }
  }
}

.button-link-text {
  font-family: "Roboto-Bold";
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  color: $primary-text;
  cursor: pointer;
}

.button-link-text-bold {
  font-family: "Roboto-Bold";
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  align-items: center;
  text-align: center;
  color: $primary-text;
  cursor: pointer;
}

.input-text-scale {
  font-family: "Roboto-Regular", "SukhumvitSet-medium";
  font-size: clamp(10px, 1.2vw, 14px);
  line-height: clamp(12px, calc(1.2vw + 2px), 16px);
  white-space: pre-line;
}
