@import "assets/stylesheets/colors";
@import "assets/stylesheets/typography";

.loadmore-component {
  height: 100%;
  .loader-spinner {
    transform: rotate(-85deg);
    width: 40px;
    height: 40px;
    animation-duration: 1s;
    animation-name: spin;
    animation-iteration-count: infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(-85deg)
    }

    to {
      transform: rotate(275deg)
    }
  }

  .infinite-scroll-component__outerdiv {
    position: relative;
    // background-color: $tertiary-navy;

    .infinite-scroll-component {

      .clickMe-container {
        opacity: 0;
        height: 0;
        left: 0;
        display: none;
        // position: absolute;
        position: relative;
        bottom: 0;
        width: 100%;
        z-index: 10;

        transition: visibility 0s, opacity 10ms linear;

        &.show {
          visibility: visible;
          opacity: 1;
          height: auto;
          display: block
        }

        background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.63) 100%);
        border-radius: 6px;

        .click-me {
          min-height: 40px;
          text-shadow: 0px 0px 4px #000000;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;

          &.text-click {
            width: 100%;
            height: 100%;

            label, span {
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }

          }
        }
      }
    }
  }
}