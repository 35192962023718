@import "assets/stylesheets/colors";

.input-text-icon-container {
    display: flex;
    flex-direction: row;

    .fontawesome-wrapper {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        background-color: $primary-text;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        margin-top: 8px;

        .input-text-icon {
            color: $primary-bg;
            font-size: 12px;
        }
    }

}
