@import "assets/stylesheets/colors";

.info-modal-container {
  text-align: center;
  padding: 24px;

  box-shadow: 0 0 1rem 0 rgba($color: $primary-bg, $alpha: 0.2);;
  background-color: rgba($color: $primary-bg, $alpha: 0.95);
  backdrop-filter: blur(5px);

  .info-checked-icon {
    align-self: center;
    margin: auto;
    width: 100px;
    height: 100px;
    object-fit: contain;
  }
}
