@import "assets/stylesheets/colors";

.input-select-container {
    width: 100%;
    position: relative;
    user-select: none;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: 2px 0 2px 0;
    &.disabled {
        cursor: default;
        opacity: 0.3;
    }

    .selected-wrapper {
        background-color: $secondary-bg;
        border-radius: 8px;
        height: 64px;
        padding: 0;
        // padding-left: 6%;
        margin: 0;
        z-index: 4;
        display: flex;
        align-items: center;
                padding-left: 6%;
        li {
            list-style: none;
            padding-right: 6%;
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;

            .input-selected-wrapper {
                flex: 1;
                white-space: normal;
                word-break: keep-all;
                h4 {
                    padding-left: 18px;
                }
            }

            .chevron-right-icon {
                color: $primary-red;
                transition: all 254ms ease-in-out;
                transform: rotate(0);
                width: clamp(6px, 1vw, 15px);
                &.expanded {
                    transform: rotate(90deg);
                }
            }
        }
    }

    .selector-wrapper {
        list-style: none;
        position: absolute;
        display: none;
        left: 0;
        z-index: 1;
        min-width: calc(100% + 4px);
        // width: 100%;
        padding-left: 6%;
        margin: .18rem -2px 0 -2px;
        padding: 0;
        // border-bottom-left-radius: 8px;
        // border-bottom-right-radius: 8px;
        // border-radius: 8px;
        background-color: $secondary-bg;
        overflow-y: auto;
        max-height: calc(60vh - 5rem);
        &.opened {
            display: block;
        }
    }
}