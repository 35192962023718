@import "assets/stylesheets/colors";

.panginate {
  .pagination {
    color: $secondary-text;
    display: flex;
    justify-content: flex-end;
    list-style: none;
  }
  .pagination a {
    font-family: "SukhumvitSet-Text";
    color: $secondary-red;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    user-select: none;
  }
  a:focus {
    outline: none;
  }
  .pagination li {
    margin: 0.2rem;
  }
  .pagination__link {
    font-weight: bold;
  }
  .pagination__link--active a {
    font-weight: bold;
    outline: 1px solid rgba(167, 171, 183, 0.2);
  }
  .pagination__link--disabled a {
    color: rgba(0, 0, 0, 0.2);
  }
}
