.alert-notification-container {
    position: fixed;
    top: calc(56px + 26px);
    overflow: hidden;
    left: 0;
    width: 100%;
    height: 0px;
    z-index: 5;
    transition: height 256ms ease 512ms;
    display: flex;
    
    &.opened {
      height: 46px;
    }
}