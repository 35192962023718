@import 'assets/stylesheets/colors';
@import 'assets/stylesheets/scrollbars';

.page-container {
  .content-wrapper {
    height: fit-content;
    .search-wrapper {
      .centered {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .user-wrapper {
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        padding: 7px;
        border-radius: 10px;
        background-color: $primary-bg;
      }
    }

    .active {
      outline: none;
      transition: all 0.2s ease-out;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      // padding: 7px;
      // border-radius: 10px;
      background-color: $primary-bg;
    }
    .detail-wrapper {
      @extend .custom-scrollbar;
      max-height: 74vh;
      height: 100vh;
      background-color: $secondary-bg;
      .detail-content {
        .icon-detail {
          height: 20px;
          width: 20px;
          border-radius: 10px;
          background-color: $primary-text;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          margin-top: 8px;
        }

        .ea_table {
          display: block;
          background: $secondary-bg;
          color: $primary-text;
          overflow: hidden;
          box-sizing: border-box;
          height: auto;
          min-width: 1800px;
        }

        .ea_table tbody,
        thead {
          flex-direction: column;
          display: flex;
          flex: 1;
          white-space: nowrap;
        }

        .ea_table tbody {
          @extend .custom-scrollbar;
          // display: flex;
          height: 65vh;
          overflow: hidden;
        }

        .ea_table thead {
          text-overflow: ellipsis;
        }

        .ea_table tr {
          width: inherit;
          display: flex;
          border-radius: 0;
          border-bottom: 1px solid $secondary-bg;
        }

        .on-select {
          background-color: $secondary-bg;
        }
        .ea_table td {
          width: inherit;
          height: 100px;
          display: flex;
          width: inherit;
          flex: 1;
          // flex-wrap: nowrap;
          align-items: center;
          white-space: normal;
          justify-content: center;
        }
        .ea_table th {
          flex-wrap: nowrap;
          width: inherit;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          // flex: 1;
        }

      }
    }
  }
  .responsive-font {
    font-size: 1rem;
  }
  .scroll {
    background-color: $secondary-bg;
  }
  .responsive-icon-bank-wrapper {
    position: relative;
    width: inherit;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .responsive-icon-bank {
    width: 25px;
    height: 25px;
    box-shadow: 2px 2px 4px 0px $primary-bg;
    border-radius: 50px;
   
  }
  .responsive-icon-bank-wrapper-rows {
    margin-left: -10px;

    // @for $i from 1 through 30 {
    //   &:nth-child(n+#{$i}) {
    //     margin-left: -10px;
    //   }
    // }
  }
  .width-150 {
    width: 150px;
  }
}
