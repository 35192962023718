$space-0: 4px;
$space-1: 8px;
$space-2: 16px;
$space-3: 24px;
$space-4: 32px;

.m {
    &0 {
        margin: $space-0;
        &-t {margin-top: $space-0;}
        &-b {margin-bottom: $space-0;}
        &-l {margin-left: $space-0;}
        &-r {margin-right: $space-0;}
        &-x {margin-left: $space-0; margin-right: $space-0;}
        &-y {margin-top: $space-0; margin-bottom: $space-0;}
    }
    &1 {
        margin: $space-1;
        &-t {margin-top: $space-1;}
        &-b {margin-bottom: $space-1;}
        &-l {margin-left: $space-1;}
        &-r {margin-right: $space-1;}
        &-x {margin-left: $space-1; margin-right: $space-1;}
        &-y {margin-top: $space-1; margin-bottom: $space-1;}
    }
    &2 {
        margin: $space-2;
        &-t {margin-top: $space-2;}
        &-b {margin-bottom: $space-2;}
        &-l {margin-left: $space-2;}
        &-r {margin-right: $space-2;}
        &-x {margin-left: $space-2; margin-right: $space-2;}
        &-y {margin-top: $space-2; margin-bottom: $space-2;}
    }
    &3 {
        margin: $space-3;
        &-t {margin-top: $space-3;}
        &-b {margin-bottom: $space-3;}
        &-l {margin-left: $space-3;}
        &-r {margin-right: $space-3;}
        &-x {margin-left: $space-3; margin-right: $space-3;}
        &-y {margin-top: $space-3; margin-bottom: $space-3;}
    }
    &4 {
        margin: $space-4;
        &-t {margin-top: $space-4;}
        &-b {margin-bottom: $space-4;}
        &-l {margin-left: $space-4;}
        &-r {margin-right: $space-4;}
        &-x {margin-left: $space-4; margin-right: $space-4;}
        &-y {margin-top: $space-4; margin-bottom: $space-4;}
    }
    &-auto {
        margin: 'auto' 
    }
}

.p {
    &0 {
        padding: $space-0;
        &-t {padding-top: $space-0;}
        &-b {padding-bottom: $space-0;}
        &-l {padding-left: $space-0;}
        &-r {padding-right: $space-0;}
        &-x {padding-left: $space-0; padding-right: $space-0;}
        &-y {padding-top: $space-0; padding-bottom: $space-0;}
    }
    &1 {
        padding: $space-1;
        &-t {padding-top: $space-1;}
        &-b {padding-bottom: $space-1;}
        &-l {padding-left: $space-1;}
        &-r {padding-right: $space-1;}
        &-x {padding-left: $space-1; padding-right: $space-1;}
        &-y {padding-top: $space-1; padding-bottom: $space-1;}
    }
    &2 {
        padding: $space-2;
        &-t {padding-top: $space-2;}
        &-b {padding-bottom: $space-2;}
        &-l {padding-left: $space-2;}
        &-r {padding-right: $space-2;}
        &-x {padding-left: $space-2; padding-right: $space-2;}
        &-y {padding-top: $space-2; padding-bottom: $space-2;}
    }
    &3 {
        padding: $space-3;
        &-t {padding-top: $space-3;}
        &-b {padding-bottom: $space-3;}
        &-l {padding-left: $space-3;}
        &-r {padding-right: $space-3;}
        &-x {padding-left: $space-3; padding-right: $space-3;}
        &-y {padding-top: $space-3; padding-bottom: $space-3;}
    }
    &4 {
        padding: $space-4;
        &-t {padding-top: $space-4;}
        &-b {padding-bottom: $space-4;}
        &-l {padding-left: $space-4;}
        &-r {padding-right: $space-4;}
        &-x {padding-left: $space-4; padding-right: $space-4;}
        &-y {padding-top: $space-4; padding-bottom: $space-4;}
    }
}

.border-rounded {
    border-radius: $space-1;
}

.flex {
    flex: 1;
}

.overflow-hidden {
    overflow: hidden;
}