.loader-container {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  z-index: 1099;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 256ms ease-in-out;

  &.hide {
    opacity: 0;
    z-index: -10;
  }

  &.show {
    opacity: 1;
    z-index: 1099;
  }
}
