@import "assets/stylesheets/colors";

.breadcrumb-container {
  .breadcrumb-wrapper {
    background-color: $secondary-bg;
    width: fit-content;
    padding: 5px 10px 5px 10px ;
    border-radius: 11px;
    span.breadcrumb-slash {
      font-size: 16px;
      cursor: default;
      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
