@import 'assets/stylesheets/colors';

.page-container {
  .box-shadow-container {
    @media only screen and (min-width: 576px) {
      height: 50%;
    }

    height: 100%;

    .responsive-title-text {
      font-size: calc(0.8em + 0.3vw);
    }

    .responsive-body-text {
      font-size: calc(0.8em + 0.3vw);
    }
  }

  .lotto-slug-dropdown-flag {
    width: 24px;
    height: 24px;
    object-fit: fill;
  }
}
